<script setup>
const { $tracking } = useNuxtApp();
const device = useDevice();

const DOWNLOAD_THE_APP_APPSFLYER = {
  desktop: 'https://hostelworld.onelink.me/IdiK/dgs27ebd',
  mobile: 'https://hostelworld.onelink.me/IdiK/6h10ylok',
};

const mobileStoreUrl = computed(() => {
  return DOWNLOAD_THE_APP_APPSFLYER.mobile;
});

const appStoreUrl = computed(() => {
  return DOWNLOAD_THE_APP_APPSFLYER.desktop;
});

const appleStoreUrl = computed(() => {
  return DOWNLOAD_THE_APP_APPSFLYER.mobile;
});
</script>

<template>
  <section
    id="download-section"
    class="download-section-wrapper"
  >
    <div class="download-header">
      <img
        :alt="$t('t_PARTIESCTA')"
        :title="$t('t_PARTIESCTA')"
        class="logo"
        width="96"
        height="96"
        loading="lazy"
        src="https://a.hwstatic.com/image/upload/v1644942643/pwa/sp/hw-icon.svg"
      />
      <h2 class="text-header">
        {{ $t('t_PARTIESCTA') }}
      </h2>
    </div>

    <div class="download-links">
      <nuxt-link
        v-show="(device.isMobileOrTablet && device.isIos) || (device.isDesktop && !device.isMacOS)"
        :title="$t('t_TEXTDOWNLOADONAPPSTORE')"
        :to="appleStoreUrl"
        rel="noopener noreferrer"
        class="download-link link-mobile-ios"
        target="_blank"
        @click="$tracking.onAppDownloadClick('iOS')"
      >
        <img
          :alt="$t('t_TEXTDOWNLOADONAPPSTORE')"
          :src="$t('t_APPLEAPPSTOREIMAGELARGE')"
          width="203"
          height="60"
          loading="lazy"
        />
      </nuxt-link>

      <nuxt-link
        v-show="device.isDesktop && device.isMacOS"
        :title="$t('t_TEXTDOWNLOADONAPPSTORE')"
        :to="appStoreUrl"
        rel="noopener noreferrer"
        class="download-link link-desktop-ios"
        target="_blank"
        @click="$tracking.onAppDownloadClick('iOS')"
      >
        <img
          :alt="$t('t_TEXTDOWNLOADONAPPSTORE')"
          :src="$t('t_APPLEAPPSTOREIMAGELARGE')"
          width="203"
          height="60"
          loading="lazy"
        />
      </nuxt-link>

      <nuxt-link
        v-show="!device.isIos"
        :title="$t('t_TEXTDOWNLOADONPLAYSTORE')"
        :to="mobileStoreUrl"
        rel="noopener noreferrer"
        class="download-link link-android"
        target="_blank"
        @click="$tracking.onAppDownloadClick('Android')"
      >
        <img
          :alt="$t('t_TEXTDOWNLOADONPLAYSTORE')"
          :src="$t('t_ANDROIDPLAYSTOREIMAGELARGE')"
          width="203"
          height="60"
          loading="lazy"
        />
      </nuxt-link>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.download-section-wrapper {
  width: 100%;
  margin-bottom: calc(#{$wds-spacing-xxxl} + #{$wds-spacing-xxxl});
  clip-path: polygon(0% 0%, 0% 100%, 100% calc(100% - #{$wds-spacing-xxl}), 100% #{$wds-spacing-xxl});
  background-color: $wds-color-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(#{$wds-spacing-xxxl} + #{$wds-spacing-m});
  padding-bottom: calc(#{$wds-spacing-xxxl} + #{$wds-spacing-xl});

  @include desktop {
    margin-bottom: calc(#{$wds-spacing-xxxl} + #{$wds-spacing-m});
  }

  .download-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-header {
      font-family: $font-family-poppins;
      font-size: wds-rem(40px);
      line-height: wds-rem(44px);
      font-weight: bold;
      color: $wds-color-white;
      text-align: center;
      margin-top: calc(#{$wds-spacing-m} + #{$wds-spacing-xs});
      margin-left: $wds-spacing-xxl;
      margin-right: $wds-spacing-xxl;

      @include tablet {
        @include title-1-bld;
      }

      @include desktop {
        font-size: wds-rem(64px);
      }
    }
  }

  .download-links {
    margin-top: calc(#{$wds-spacing-m} + #{$wds-spacing-xs});
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: calc(#{$wds-spacing-s} + #{$wds-spacing-xs});
  }
}
</style>
